import { render, staticRenderFns } from "../index.html?vue&type=template&id=74e07016&external"
import script from "../index.ts?vue&type=script&lang=ts&external"
export * from "../index.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/agent/_work/2/s/Be.Vlaanderen.Mo.Vue/ClientApp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74e07016')) {
      api.createRecord('74e07016', component.options)
    } else {
      api.reload('74e07016', component.options)
    }
    module.hot.accept("../index.html?vue&type=template&id=74e07016&external", function () {
      api.rerender('74e07016', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/mobiliteit/buitenlandsvoertuig/_land/_nummerplaat1/_nummerplaat2/index.vue"
export default component.exports