import { render, staticRenderFns } from "../index.html?vue&type=template&id=51c81708&scoped=true&external"
import script from "../index.ts?vue&type=script&lang=ts&external"
export * from "../index.ts?vue&type=script&lang=ts&external"
import style0 from "./index.vue?vue&type=style&index=0&id=51c81708&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51c81708",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/agent/_work/2/s/Be.Vlaanderen.Mo.Vue/ClientApp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('51c81708')) {
      api.createRecord('51c81708', component.options)
    } else {
      api.reload('51c81708', component.options)
    }
    module.hot.accept("../index.html?vue&type=template&id=51c81708&scoped=true&external", function () {
      api.rerender('51c81708', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/documentendienst/verzending/_messageid1/_messageid2/index.vue"
export default component.exports