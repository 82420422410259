import { render, staticRenderFns } from "./mo-error.html?vue&type=template&id=6d779796&external"
import script from "./mo-error.ts?vue&type=script&lang=ts&external"
export * from "./mo-error.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/agent/_work/2/s/Be.Vlaanderen.Mo.Vue/ClientApp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6d779796')) {
      api.createRecord('6d779796', component.options)
    } else {
      api.reload('6d779796', component.options)
    }
    module.hot.accept("./mo-error.html?vue&type=template&id=6d779796&external", function () {
      api.rerender('6d779796', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/shared/mo-error/mo-error.vue"
export default component.exports