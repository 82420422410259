import { render, staticRenderFns } from "./history-status-info.html?vue&type=template&id=3c0656cc&scoped=true&external"
import script from "./history-status-info.ts?vue&type=script&lang=ts&external"
export * from "./history-status-info.ts?vue&type=script&lang=ts&external"
import style0 from "./history-status-info.vue?vue&type=style&index=0&id=3c0656cc&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c0656cc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/agent/_work/2/s/Be.Vlaanderen.Mo.Vue/ClientApp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3c0656cc')) {
      api.createRecord('3c0656cc', component.options)
    } else {
      api.reload('3c0656cc', component.options)
    }
    module.hot.accept("./history-status-info.html?vue&type=template&id=3c0656cc&scoped=true&external", function () {
      api.rerender('3c0656cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/kadaster/ownership-history/history-status-info/history-status-info.vue"
export default component.exports